// @mui
import { useTheme } from "@mui/material/styles";
import { Stack, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
// utils
import { bgBlur } from "@/utils/cssStyles";
// components
import { HEADER, NAV } from "@/config";
import Iconify from "@/Components/iconify/Iconify";
import Image from "@/Components/image/Image";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import useOffSetTop from "@/hooks/useOffSetTop";
import useResponsive from "@/hooks/useResponsive";
import MessagesPopover from "./MessagesPopover";
//

export default function Header({ onOpenNav }) {
    const theme = useTheme();
    const isDesktop = useResponsive("up", "lg");

    const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP);

    const renderContent = (
        <>
            {!isDesktop && (
                <IconButton
                    onClick={onOpenNav}
                    sx={{ mr: 1, color: "text.primary" }}
                >
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>
            )}

            <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1.5 }}
            >
                <MessagesPopover />
                <NotificationsPopover />

                <AccountPopover />
            </Stack>
        </>
    );

    return (
        <AppBar
            sx={{
                boxShadow: "none",
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(["height"], {
                    duration: theme.transitions.duration.shorter,
                }),
                bgcolor: "background.default",
                borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
                ...(isDesktop && {
                    width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
                    height: HEADER.H_DASHBOARD_DESKTOP,
                    ...(isOffset && {
                        height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
                    }),
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 },
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}
