import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
    Box,
    Divider,
    Typography,
    Stack,
    MenuItem,
    Link as MuiLink,
} from "@mui/material";
// components
import { CustomAvatar } from "@/Components/custom-avatar";
import MenuPopover from "@/Components/menu-popover";
import { IconButtonAnimate } from "@/Components/animate";
import { Link, router, usePage } from "@inertiajs/react";
import Iconify from "@/Components/iconify/Iconify";

export default function AccountPopover() {
    const {
        auth: { user },
    } = usePage().props;
    const media =
        user.media && user.media[0]
            ? user.media[0]?.original_url.replace(
                  /^https?:\/\/localhost\//,
                  "/"
              )
            : user.gender != "M"
            ? "/assets/images/default-women.png"
            : "/assets/images/default-men.png";

    const OPTIONS = [
        {
            label: "Profile",
            linkTo: route("admin.profile.edit"),
        },
        {
            label: "Settings",
            linkTo: route("admin.profile.index"),
        },
    ];

    const [openPopover, setOpenPopover] = useState(null);

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleLogout = () => {
        handleClosePopover();
        router.post(route("logout"));
    };

    return (
        <>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Box>
                    <IconButtonAnimate
                        onClick={handleOpenPopover}
                        sx={{
                            p: 0,
                            ...(openPopover && {
                                "&:before": {
                                    zIndex: 1,
                                    content: "''",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    bgcolor: (theme) =>
                                        alpha(theme.palette.grey[900], 0.8),
                                },
                            }),
                        }}
                    >
                        <CustomAvatar
                            src={media}
                            name={user?.fullname}
                            alt={user?.fullname}
                        />
                    </IconButtonAnimate>
                </Box>
                <Box onClick={handleOpenPopover} sx={{ cursor: "pointer" }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={0.5}
                        sx={{ color: "primary.main" }}
                    >
                        <Typography>{user?.firstname}</Typography>{" "}
                        <Iconify icon="mdi:chevron-down" />
                    </Stack>
                </Box>
            </Stack>

            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                sx={{ width: 200, p: 0, mt: 1.5 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.fullname}
                    </Typography>

                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                        noWrap
                    >
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: "dashed" }} />

                <Stack sx={{ p: 1 }}>
                    {OPTIONS.map((option) => (
                        <MuiLink
                            component={Link}
                            href={option.linkTo}
                            key={option.label}
                            underline="none"
                        >
                            <MenuItem>{option.label}</MenuItem>
                        </MuiLink>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: "dashed" }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </MenuPopover>
        </>
    );
}
