import { useState } from "react";
// @mui
import {
    Box,
    Stack,
    Badge,
    Avatar,
    Typography,
    ListItemText,
    ListItemAvatar,
    ListItemButton,
} from "@mui/material";
// utils
import { fToNow } from "@/utils/formatTime";
// components
import Iconify from "@/Components/iconify";
import MenuPopover from "@/Components/menu-popover";
import { IconButtonAnimate } from "@/Components/animate";
import { Link } from "@inertiajs/react";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

export default function MessagesPopover() {
    const [openPopover, setOpenPopover] = useState(null);

    const totalUnRead = 0;

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    return (
        <>
            <IconButtonAnimate
                color={openPopover ? "primary" : "default"}
                onClick={handleOpenPopover}
                sx={{ width: 40, height: 40 }}
            >
                <Badge badgeContent={totalUnRead} color="error">
                    <Icon icon="line-md:chat-round-dots-filled" />
                </Badge>
            </IconButtonAnimate>

            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                sx={{ width: 360, p: 0 }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        py: 2,
                        px: 2.5,
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">Messages</Typography>

                        <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                        >
                            You have {totalUnRead} unread messages
                        </Typography>
                    </Box>
                </Box>
            </MenuPopover>
        </>
    );
}

function NotificationItem({ notification }) {
    const { data, id, read_at, created_at } = notification;

    const routeName = data?.routeName
        ? data?.params
            ? route(data.routeName, data.params)
            : route(data.routeName)
        : "#";

    const beforeRedirect = (e) => {
        if (read_at === null && id) {
            e.preventDefault();
        }
    };

    return (
        <ListItemButton
            sx={{
                py: 1.5,
                px: 2.5,
                mt: "1px",
                ...(read_at === null && {
                    bgcolor: "action.selected",
                }),
            }}
            LinkComponent={Link}
            href={routeName}
            onClick={beforeRedirect}
        >
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
            </ListItemAvatar>

            <ListItemText
                disableTypography
                primary={title}
                secondary={
                    <Stack
                        direction="row"
                        sx={{
                            mt: 0.5,
                            typography: "caption",
                            color: "text.disabled",
                        }}
                    >
                        <Iconify
                            icon="eva:clock-fill"
                            width={16}
                            sx={{ mr: 0.5 }}
                        />
                        <Typography variant="caption">
                            {fToNow(created_at)}
                        </Typography>
                    </Stack>
                }
            />
        </ListItemButton>
    );
}
