import Iconify from "@/Components/iconify/Iconify";
import SvgColor from "@/Components/svg-color";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor
        src={`/storage/assets/icons/navbar/${name}.svg`}
        sx={{ width: 1, height: 1 }}
    />
);

const ICONS = {
    dashboard: icon("ic_dashboard"),
    message: (
        <Icon icon="line-md:chat-round-dots-filled" width={24} height={24} />
    ),
    nurse: <Iconify icon="healthicons:nurse-outline" />,
    users: <Icon icon="mdi:account-group-outline" />,
    work: <Icon icon="mdi:work-outline" width="2rem" height="2rem" />,
    ads: <Icon icon="mdi:ads" width="2rem" height="2rem" />,
    category: icon("icon-park_category"),
    post: icon("mdi_post"),
    mail: <Iconify icon="eva:email-outline" />,
};

const navConfig = [
    // ----------------------------------------------------------------------
    {
        items: [
            {
                title: "Dashboard",
                path: route("admin.index"),
                icon: ICONS.dashboard,
                routeNames: ["admin.index"],
            },
            {
                title: "Chat Messages",
                path: route("admin.messaging.inbox"),
                icon: ICONS.message,
                routeNames: ["admin.messaging.inbox"],
            },
        ],
    },
    // CLIENT
    // ----------------------------------------------------------------------
    {
        subheader: "client",
        items: [
            {
                title: "Professionals",
                // path: route('admin.index'),
                icon: ICONS.nurse,
                routeNames: ["admin.professional.index"],
                path: route("admin.professional.index"),
            },
            {
                title: "employers",
                // path: route('admin.index'),
                icon: ICONS.users,
                routeNames: [
                    "admin.employer.index",
                    "admin.organization.index",
                ],
                children: [
                    {
                        title: "Individual",
                        routeNames: ["admin.employer.index"],
                        path: route("admin.employer.index"),
                    },
                    {
                        title: "Organization",
                        routeNames: ["admin.organization.index"],
                        path: route("admin.organization.index"),
                    },
                ],
            },
        ],
    },
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
        subheader: "management",
        items: [
            // {
            //     title: "category",
            //     icon: ICONS.category,
            //     routeNames: ["admin.category.index", "admin.category.create"],
            //     children: [
            //         {
            //             title: "Create",
            //             routeNames: ["admin.category.create"],
            //             path: route("admin.category.create"),
            //         },
            //         {
            //             title: "List",
            //             routeNames: ["admin.category.index"],
            //             path: route("admin.category.index"),
            //         },
            //     ],
            // },
            {
                title: "jobs",
                icon: ICONS.work,
                routeNames: [
                    "admin.job.index",
                    "admin.category.index",
                    "admin.category.edit",
                ],
                children: [
                    {
                        title: "list",
                        routeNames: ["admin.job.index"],
                        path: route("admin.job.index"),
                    },
                    {
                        title: "Categories",
                        routeNames: ["admin.category.index"],
                        path: route("admin.category.index"),
                    },
                ],
            },
            {
                title: "ads",
                icon: ICONS.ads,
                routeNames: ["admin.ads.index"],
                children: [
                    {
                        title: "list",
                        routeNames: ["admin.ads.index"],
                        path: route("admin.ads.index"),
                    },
                ],
            },
        ],
    },
    // FEED
    // ----------------------------------------------------------------------
    {
        subheader: "feed",
        items: [
            {
                title: "category",
                icon: ICONS.category,
                routeNames: [
                    "admin.post.category.index",
                    "admin.post.category.create",
                ],
                children: [
                    // {
                    // 	title: 'Create',
                    // 	routeNames: ['admin.post.category.create'],
                    // 	path: route('admin.post.category.create'),
                    // },
                    {
                        title: "List",
                        routeNames: ["admin.post.category.index"],
                        path: route("admin.post.category.index"),
                    },
                ],
            },
            {
                title: "post",
                icon: ICONS.post,
                routeNames: ["admin.post.index", "admin.post.create"],
                children: [
                    {
                        title: "Create",
                        routeNames: ["admin.post.create"],
                        path: route("admin.post.create"),
                    },
                    {
                        title: "List",
                        routeNames: ["admin.post.index"],
                        path: route("admin.post.index"),
                    },
                ],
            },
        ],
    },

 // EMAIL
// ----------------------------------------------------------------------
{
    subheader: "mail",
    items: [
        {
            title: "email",
            icon: ICONS.mail,
            routeNames: [
                "admin.mail.index",
                "admin.mail.create",
                "admin.mail.email-category.create",
            ],
            children: [
                {
                    title: "List",
                    routeNames: ["admin.mail.index"],
                    path: route("admin.mail.index"),
                },
                {
                    title: "Create",
                    routeNames: ["admin.mail.create"],
                    path: route("admin.mail.create"),
                },
                {
                    title: "Categories",
                    routeNames: ["admin.mail.email-category.create"],
                    path: route("admin.mail.email-category.create"),
                }
            ],
        },
    ],
}

];

export default navConfig;
