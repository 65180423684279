import { useEffect, useState } from "react";
import { usePage } from "@inertiajs/react";
import { useSnackbar } from "notistack";
import Header from "./header";
import Main from "./Main";
import { Box } from "@mui/material";
import NavVertical from "./nav/NavVertical";

export default function AdminLayout({ children }) {
	const { flash } = usePage().props;
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (flash.message) {
			enqueueSnackbar(flash.message, { variant: flash.type || "default" });
			flash.message = null;
		}
	}, [flash]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Header onOpenNav={handleOpen} />
			<Box
				sx={{
					display: { lg: 'flex' },
					minHeight: { lg: 1 },
				}}
			>
				<NavVertical openNav={open} onCloseNav={handleClose} />
				<Main>
					{children}
				</Main>
			</Box>

		</>
	)
}
