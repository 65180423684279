import PropTypes from "prop-types";
// @mui
import { Box, Stack, Drawer, Typography } from "@mui/material";
// hooks
import useResponsive from "@/hooks/useResponsive";
// config
import { NAV } from "../../../config";
// components
import Scrollbar from "@/Components/scrollbar";
import { NavSectionVertical } from "@/Components/nav-section";
//
import navConfig from "./config";
import NavDocs from "./NavDocs";
import NavAccount from "./NavAccount";
import Image from "@/Components/image/Image";
import { bgBlur } from "@/utils/cssStyles";
import { usePage } from "@inertiajs/react";

// ----------------------------------------------------------------------

NavVertical.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function NavVertical({ openNav, onCloseNav }) {
    const isDesktop = useResponsive("up", "lg");
    const { user } = usePage().props;

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                "& .simplebar-content": {
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                }}
            >
                <Stack
                    direction="row"
                    display="flex"
                    justifyContent="center"
                    spacing={2}
                >
                    <Image
                        src="/assets/logo/pinoycare.png"
                        sx={{ width: 20 }}
                        imgSx={{ objectFit: "contain" }}
                        alt="UpCare"
                    />
                    <Typography
                        variant="h5"
                        component="span"
                        color="primary"
                        fontWeight="bold"
                    >
                        Up
                        <Box component="span" sx={{ color: "red" }}>
                            care
                        </Box>
                    </Typography>
                </Stack>

                <NavAccount />
            </Stack>

            <NavSectionVertical data={navConfig} />

            <Box sx={{ flexGrow: 1 }} />

            <NavDocs />
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.W_DASHBOARD },
            }}
        >
            {isDesktop ? (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV.W_DASHBOARD,
                            bgcolor: "background.default",
                            borderRightStyle: "dashed",
                        },
                    }}
                    SlideProps={{ direction: "right" }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: {
                            width: NAV.W_DASHBOARD,
                        },
                    }}
                    SlideProps={{ direction: "right" }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
